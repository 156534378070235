import PartySocket from "partysocket";
import { CalendarMessage, ChatMessage, DisconnectMessage, LoginMessage, Message, MessageType } from "./models";

const socket = new PartySocket({
  //host: 'localhost:1999',
  host: 'perryhagen-house-party.reedperry.partykit.dev',
  room: 'my-room',
})

let leave, chatLog, calendar, input, sendCalendarEvent, submit, username;

socket.addEventListener('message', evt => {
  try {
    const data = JSON.parse(evt.data);
    let msg = data.message;
    let sender = data.sender;
    switch (msg.type) {
        case MessageType.CHAT:
            addMessageToChatLog(msg, sender);
            break;
        case MessageType.CALENDAR:
            addEventToCalendar(msg, sender);
            break;
        case MessageType.LOGIN:
            addLoginToChatLog(msg, sender);
            break;
        case MessageType.DISCONNECT:
            addDisconnectToChatLog(msg, sender);
            break;
    }
  } catch (err) {
    console.error(err);
 }
});

window.addEventListener('load', () => {
  findElements();

  while (!username) {
    username = prompt('Enter your username:');
  }

  signIn(username);

  submit.addEventListener('click', evt => {
    let msg: Message = { type: MessageType.CHAT, content: input.value };
    socket.send(JSON.stringify(msg));
  });

  sendCalendarEvent.addEventListener('click', evt => {
    let msg: Message = { type: MessageType.CALENDAR, eventName: 'Birthday Party', start: new Date(), end: new Date(Date.now() + 30000)};
    socket.send(JSON.stringify(msg));
  });

  leave.addEventListener('click', evt => {
    socket.close();
    addPlainTextToChatLog('You disconnected.');
  });
});

function signIn(username: string): void {
 sendMessage({
   type: MessageType.LOGIN, username 
 });
}

function addMessageToChatLog(msg: ChatMessage, sender: string): void {
  let p = document.createElement('p');
  p.innerHTML = `<strong>${sender}:</strong> ${msg.content}`;
  chatLog.append(p);
}

function addLoginToChatLog(msg: LoginMessage, sender: string): void {
  let p = document.createElement('p');
  p.innerHTML = `${msg.username} connected.`;
  chatLog.append(p);
}

function addDisconnectToChatLog(msg: DisconnectMessage, sender: string): void {
  let p = document.createElement('p');
  p.innerHTML = `${msg.username} disconnected.`;
  chatLog.append(p);
}

function addEventToCalendar(msg: CalendarMessage, sender: string): void {
  let p = document.createElement('p');
  const startDate = new Date(msg.start);
  const endDate = new Date(msg.end);
  p.innerHTML = `<strong>${msg.eventName}:</strong> ${startDate.toLocaleTimeString()} - ${endDate.toLocaleTimeString()}`;
  calendar.append(p);
}

function addPlainTextToChatLog(text: string): void {
  let p = document.createElement('p');
  p.innerHTML = text;
  chatLog.append(p);
}

function sendMessage(message: Message): void {
  socket.send(JSON.stringify(message));
}

function findElements() {
  input = document.getElementById('message-input');
  submit = document.getElementById('submit');
  sendCalendarEvent = document.getElementById('send-calendar-event');
  chatLog = document.getElementById('chat-log');
  calendar = document.getElementById('calendar');
  leave = document.getElementById('leave');

  if ([input, submit, sendCalendarEvent, chatLog, calendar].some(x => !x)) {
      throw new Error('Missing elements on page');
  }
}

